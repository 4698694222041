<template>
  <div id="guide">
    <h1 class="a1g"
        :class="{ 'margin-webview': checkWebview(), 'padding-has-caution': isHasCaution }">ご利用ガイド</h1>

    <div class="s1 box">
      <div class="s1_flex">
        <div class="s1_bx">
          <h2 class="a1g">What is<br class="sp"> ChibiJob?</h2>
          <h3 class="a1g">ChibiJobとは？</h3>
          <p>様々なキャンペーンに参加してポイントGET。<br>
            ChibiJob限定のプログラムで、毎日お得に楽しめる懸賞サイトです。<br>
            貯めたポイントは1P=1円として、電子マネー・他社ポイントに交換できます。</p>
        </div>
        <div class="s1_img">
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s1_img.png 1x,../assets/images/page-other/s1_img@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s1_img-s.png">
            <img src="../assets/images/page-other/s1_img.png" alt="CHIBI JOBとは？" class="switching">
          </picture>
        </div>
      </div>
    </div>

    <div class="s2 box">
      <h2 class="a1g">How to save points</h2>
      <h3 class="a1g">ポイントの貯め方</h3>
      <p>ChibiJob内の掲載サービスを利用してポイントゲット！</p>

      <ul>
        <li>
          <div class="s2_ttl_flex">
            <div class="s2_ttl_bx"><h4 class="jost">Step1</h4></div>
            <div class="s2_ttl_bx"><h5 class="a1g">［広告を探す］</h5></div>
          </div>
          <h6>ランキング・カテゴリ・検索ワード入力などから広告を選択します。</h6>
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s2_stepimg01.png 1x,../assets/images/page-other/s2_stepimg01@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s2_stepimg01-s.png">
            <img src="../assets/images/page-other/s2_stepimg01.png" alt="［広告を探す］" class="switching">
          </picture>
        </li>
        <li>
          <div class="s2_ttl_flex">
            <div class="s2_ttl_bx"><h4 class="jost">Step2</h4></div>
            <div class="s2_ttl_bx"><h5 class="a1g">［サービスを利用］</h5></div>
          </div>
          <h6>広告の詳細を確認し、サービスを利用します。</h6>
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s2_stepimg02.png 1x,../assets/images/page-other/s2_stepimg02@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s2_stepimg02-s.png">
            <img src="../assets/images/page-other/s2_stepimg02.png" alt="［サービスを利用］" class="switching">
          </picture>
        </li>
        <li>
          <div class="s2_ttl_flex">
            <div class="s2_ttl_bx"><h4 class="jost">Step3</h4></div>
            <div class="s2_ttl_bx"><h5 class="a1g">［承認でポイント獲得］</h5></div>
          </div>
          <h6>反映期間を過ぎて、条件クリアと承認されたらポイント獲得！</h6>
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s2_stepimg03.png 1x,../assets/images/page-other/s2_stepimg03@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s2_stepimg03-s.png">
            <img src="../assets/images/page-other/s2_stepimg03.png" alt="［承認でポイント獲得］" class="switching">
          </picture>
        </li>
      </ul>

      <div class="s2_point">
        <div class="s2_point_ttl a1g">様々なコンテンツがあるので、自分に合ったサービスを利用できます。</div>
        <p>［応募で貯める/キャンペーンに応募etc］</p>
        <p>［無料で貯める/資料請求・会員登録etc］</p>
        <p>［ショッピングで貯める/ネットショップ・クレジットカード作成etc］</p>
        <p>［簡単作業で貯める/アンケートetc］</p>
        <p>［友達紹介で貯める/X(旧twitter)でシェア・LINE］</p>
      </div>
    </div>

    <div class="s3 box">
      <div class="s3_flex">
        <div class="s3_bx">
          <h2 class="a1g">How to view<br class="sp"> ad pages</h2>
          <h3 class="a1g">広告ページの見方</h3>
          <p class="text-indent">①POINT GET<br>クリック（タップ）するとサービスサイトに遷移いたします。</p>
          <p class="text-indent">②ポイント数<br>サービス利用で獲得できるポイント数です。</p>
          <p class="text-indent">③獲得条件<br>ポイントを獲得するための条件です。</p>
          <p class="text-indent">④予定反映<br>サービス利用完了までの時間目安です。</p>
          <p class="text-indent">⑤確定反映<br>サービス利用から承認・ポイント獲得までの期間目安です。<br>承認ステータスはマイページにてご確認頂けます。</p>
          <p class="text-indent">⑥スマホ用QRコード<br>クリックするとQRコードが表示されます。<br>お手持ちのスマホで読み取ると、スマートフォン版のページにアクセスすることが出来ます。</p>
          <p class="text-indent">⑦シェアボタン<br>広告をお友達にシェアすることができます。</p>
          <p class="text-indent">⑧お気に入り登録ボタン<br>このボタンを押すことでマイページのお気に入り一覧に登録されます。</p>
          <p class="text-indent">⑨お買い物リスト<br>このボタンを押すことでお買い物リスト一覧に登録されます。</p>
        </div>
        <div class="s3_img">
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s3_img.png 1x,../assets/images/page-other/s3_img@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s3_img-s.png">
            <img src="../assets/images/page-other/s3_img.png" alt="広告ページの見方" class="switching">
          </picture>
        </div>
      </div>
    </div>

    <div class="s4 box">
      <h2 class="a1g">Check Points</h2>
      <h3 class="a1g">ポイントの確認</h3>
      <p>ポイントの獲得状況はマイページから確認ができます。</p>
      <picture>
        <source media="(min-width:768px)" srcset="../assets/images/page-other/s4_img.png 1x,../assets/images/page-other/s4_img@2x.png 2x">
        <source media="(max-width:767px)" srcset="../assets/images/page-other/s4_img-s.png">
        <img src="../assets/images/page-other/s4_img.png" alt="ポイントの確認" class="switching">
      </picture>
    </div>

    <div class="s5 box">
      <h2 class="a1g">Redeeming Points</h2>
      <h3 class="a1g">ポイントの交換</h3>

      <ul>
        <li>
          <div class="s5_ttl_flex">
            <div class="s5_ttl_bx"><h4 class="jost">Step1</h4></div>
            <div class="s5_ttl_bx"><h5 class="a1g">［交換先を選択］</h5></div>
          </div>
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s5_stepimg01.png 1x,../assets/images/page-other/s5_stepimg01@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s5_stepimg01-s.png">
            <img src="../assets/images/page-other/s5_stepimg01.png" alt="［交換先を選択］" class="switching">
          </picture>
          <h6>マイページのポイント交換から一覧へ進み、お好きな交換先をクリックして交換先詳細ページに進みます。</h6>
        </li>
        <li>
          <div class="s5_ttl_flex">
            <div class="s5_ttl_bx"><h4 class="jost">Step2</h4></div>
            <div class="s5_ttl_bx"><h5 class="a1g">［本人確認］</h5></div>
          </div>
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s5_stepimg02.png 1x,../assets/images/page-other/s5_stepimg02@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s5_stepimg02-s.png">
            <img src="../assets/images/page-other/s5_stepimg02.png" alt="［本人確認］" class="switching">
          </picture>
          <h6>詳細ページの内容をご確認の上「ポイントを交換する」をクリック（タップ）します。</h6>
        </li>
        <li>
          <div class="s5_ttl_flex">
            <div class="s5_ttl_bx"><h4 class="jost">Step3</h4></div>
            <div class="s5_ttl_bx"><h5 class="a1g">［交換申請］</h5></div>
          </div>
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/page-other/s5_stepimg03.png 1x,../assets/images/page-other/s5_stepimg03@2x.png 2x">
            <source media="(max-width:767px)" srcset="../assets/images/page-other/s5_stepimg03-s.png">
            <img src="../assets/images/page-other/s5_stepimg03.png" alt="［交換申請］" class="switching">
          </picture>
          <h6>各交換先で条件をご確認の上、ポイント交換を進めてください。</h6>
        </li>
      </ul>

    </div>

    <div class="s6 box">
      <h2 class="a1g">How to View My Page</h2>
      <h3 class="a1g">マイページの見方</h3>
      <picture>
        <source media="(min-width:768px)" srcset="../assets/images/page-other/s6_img.png 1x,../assets/images/page-other/s6_img@2x.png 2x">
        <source media="(max-width:767px)" srcset="../assets/images/page-other/s6_img-s.png">
        <img src="../assets/images/page-other/s6_img.png" alt="マイページの見方" class="switching">
      </picture>
      <p class="text-indent">①利用可能ポイント<br>現在の保有ポイントです。</p>
      <p class="text-indent">②判定中ポイント<br>現在審査中のポイントです。</p>
      <p class="text-indent">③ポイント通帳：ポイント獲得・交換の明細です。<br>
      ポイント明細：ポイントの獲得履歴/ステータス等を記載しています。<br>
      ポイント交換履歴：ポイントの交換履歴/ステータス等を記載しています。</p>
      <p class="text-indent">④友達紹介<br>今まで紹介した友達の人数です。</p>
      <p class="text-indent">⑤お知らせ<br>キャンペーン情報や、ポイント期限のお知らせなど重要な情報も併せてご案内いたします。</p>
      <p class="text-indent">⑥お気に入り<br>お気に入りに登録した広告の一覧です。</p>
      <p class="text-indent">⑦買うものリスト<br>広告以外にフリーワードでも登録が可能です。<br>日々のお買い物リストとして是非ご活用ください。</p>

    </div>

  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'Guide',

  mixins: [Common],

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }
}
</script>

<style lang="scss" scoped>
//------------------------------------------------------------------ Color ---*/
$orange: #C66C44;
$orange-gray: #D98F6D;
$orange-bg: #F5F0ED;
//------------------------------------------------------------ Media mixin ---*/

@function vw($num) {
  $maxWidth: 1128; //レイアウト最大幅
  // $val_vw: round($num / $maxWidth * 100 * 1000) / 1000;
  $val_vw: calc(round($num / $maxWidth * 100 * 1000) / 1000);
  $val_max: round($maxWidth * ($val_vw / 100)) * 1px;
  @return unquote('min(#{$val_vw * 1vw}, #{$val_max})');
}

$sp: 767px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 767;
@function pv($num, $width: $viewport) {
  // @return (1vw * $num / $width * 100);
  @return calc(1vw * $num * 100 / $width);
}

//--------------------------------------------------------------- Contents ---*/

.a1g {
  font-family: $font-family-title;
}

#guide {
  max-width: 1160px;
  padding: 0 15px;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow-x: hidden;
  @media #{$info-phone} {
    max-width: 100%;
    padding: 0 18px;
  }

  h1 {
    font-size: vw(36);
    color: $orange;
    text-align: center;
    margin: vw(100) auto vw(55);
    @media #{$info-phone} {
      font-size: pv(64);
      margin: 17vw auto 10vw;
    }
    &.padding-has-caution {
      @media #{$info-phone} {
        padding: 45px 18px 0;
      }
    }
    &.margin-webview {
      margin: 10vw auto;
    }
  }

  .box {
    width: 100%;
    border-radius: 6px;
    border: 6px solid $orange;
    background-color: $orange-bg;
    padding: vw(70);
    @media #{$info-phone} {
      width: 100%;
      border: 3px solid $orange;
      padding: 10vw 5vw;
    }
  }

  .s1 {
    margin: 0 auto vw(70);
    @media #{$info-phone} {
      margin: 0 auto 5vw;
    }

    .s1_flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      @media #{$info-phone} {
        flex-direction: column;
      }
    }

    .s1_bx {
      flex-basis: 60%;
      max-width: 60%;
      text-align: left;
      @media #{$info-phone} {
        flex-basis: 100%;
        max-width: 100%;
        text-align: center;
      }

      h2 {
        font-size: vw(48);
        letter-spacing: 0.01em;
        -webkit-text-stroke: 1px $orange;
        color: #fff;
        line-height: 1;
        margin-bottom: vw(10);
        position: relative;
        @media #{$info-phone} {
          font-size: pv(72);
          line-height: 1.3;
          margin-bottom: 2vw;
        }
      }

      h2:after {
        content: "";
        background-image: url("../assets/images/page-other/s1_ttl_after.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: vw(139);
        height: vw(158);
        position: absolute;
        //top: -vw(10);
        //right: -vw(50);
        @media #{$info-phone} {
          width: 37vw;
          height: 42vw;
          top: 37vw;
          right: inherit;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
        }
      }

      h3 {
        font-size: vw(28);
        color: $orange;
        margin-bottom: vw(40);
        @media #{$info-phone} {
          font-size: pv(40);
          margin: 0 auto 50vw;
        }
      }

      p {
        font-size: vw(18);
        line-height: 1.7;
        @media #{$info-phone} {
          font-size: pv(32);
          text-align: left;
          margin: 0 0 3vw;
        }
      }
    }

    .s1_img {
      flex-basis: 40%;
      max-width: 40%;
      text-align: right;
      @media #{$info-phone} {
        flex-basis: 100%;
        max-width: 100%;
        text-align: center;
      }

      img {
        max-width: vw(355);
        @media #{$info-tablet-horizontal} {
          max-width: vw(320);
        }
        @media #{$info-phone} {
          max-width: 100%;
        }
      }
    }
  }

  .s2 {
    margin: 0 auto vw(70);
    text-align: center;
    @media #{$info-phone} {
      margin: 0 auto 5vw;
    }

    h2 {
      font-size: vw(48);
      letter-spacing: 0.01em;
      -webkit-text-stroke: 1px $orange;
      color: #fff;
      line-height: 1;
      margin-bottom: vw(10);
      position: relative;
      @media #{$info-phone} {
        font-size: pv(72);
        line-height: 1.3;
        margin-bottom: 2vw;
      }
    }

    h3 {
      font-size: vw(28);
      color: $orange;
      margin-bottom: vw(40);
      @media #{$info-phone} {
        font-size: pv(40);
        margin-bottom: 4vw;
      }
    }

    p {
      font-size: vw(18);
      line-height: 1.7;
      margin-bottom: vw(50);
      @media #{$info-phone} {
        font-size: pv(32);
        margin-bottom: 3vw;
        text-align: left;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0 auto vw(35);
      @media #{$info-phone} {
        flex-direction: column;
        margin: 0 auto 3.5vw;
      }
    }

    li {
      flex-basis: vw(310);
      max-width: vw(310);
      border-radius: 6px;
      border: 1px solid $orange;
      background-color: #fff;
      padding: vw(15);
      position: relative;
      @media #{$info-phone} {
        flex-basis: 100%;
        max-width: 100%;
        border-radius: 6px;
        padding: 4vw;
        margin-bottom: 5vw;
      }

      .s2_ttl_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$info-phone} {
          flex-direction: column;
        }
      }

      .s2_ttl_bx {
        h4 {
          font-size: vw(50);
          letter-spacing: -0.02em;
          font-weight: 300;
          color: $orange-gray;
          @media #{$info-phone} {
            font-size: pv(100);
            margin-bottom: 2vw;
          }
        }

        h5 {
          font-size: vw(14);
          color: $orange;
          line-height: 1;
          @media #{$info-phone} {
            font-size: pv(32);
          }
        }
      }

      h6 {
        margin: 15px 0;
        text-align: left;
        font-size: vw(16);
        line-height: 2;
        font-weight: normal;
        @media #{$info-phone} {
          margin: 2.5vw 0;
          font-size: pv(32);
        }
      }

      img {
        max-width: vw(280);
        @media #{$info-phone} {
          max-width: 100%;
        }
      }
    }

    li:after {
      content: "";
      background-image: url("../assets/images/page-other/step_arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: vw(44);
      height: vw(44);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      right: vw(-24);
      z-index: 3;
      @media #{$info-phone} {
        background-image: url("../assets/images/page-other/step_arrow-s.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 11vw;
        height: 11vw;
        top: inherit;
        bottom: -8vw;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        right: inherit;
      }
    }

    li:last-child:after{
      content: none;
    }

    .s2_point {
      width: 100%;
      border-radius: 6px;
      border: 1px solid $orange;
      background-color: #fff;
      padding: vw(55) vw(100);
      text-align: left;
      @media #{$info-phone} {
        padding: 5vw;
      }

      .s2_point_ttl {
        font-size: vw(24);
        font-weight: bold;
        color: $orange;
        margin-bottom: vw(20);
        @media #{$info-phone} {
          font-size: pv(32);
          margin-bottom: 2.5vw;
          line-height: 1.3;
        }
      }

      p {
        font-size: vw(18);
        line-height: 1;
        margin-bottom:15px;
        padding-left: 22px;
        position: relative;
        @media #{$info-phone} {
          font-size: pv(32);
          line-height: 1.5;
          margin-bottom: 2vw;
          padding-left: 6.5vw;
        }
      }

      p:before {
        content: "";
        background-image: url("../assets/images/page-other/point_ico.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: vw(22);
        height: vw(22);
        position: absolute;
        top: -3px;
        left: 0;
        margin-right: vw(22);
        @media #{$info-phone} {
          width: 5vw;
          height: 5vw;
          top: 1vw;
          margin-right: 6.5vw;
        }
      }
    }
  }

  .s3 {
    margin: 0 auto vw(70);
    @media #{$info-phone} {
      margin: 0 auto 5vw;
    }

    .s3_flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      @media #{$info-phone} {
        flex-direction: column;
      }
    }

    .s3_bx {
      flex-basis: 58%;
      max-width: 58%;
      text-align: left;
      @media #{$info-phone} {
        flex-basis: 100%;
        max-width: 100%;
        text-align: center;
      }

      h2 {
        font-size: vw(48);
        letter-spacing: 0.01em;
        -webkit-text-stroke: 1px $orange;
        color: #fff;
        line-height: 1;
        margin-bottom: vw(10);
        @media #{$info-phone} {
          font-size: pv(72);
          line-height: 1.3;
          margin-bottom: 2vw;
        }
      }

      h3 {
        font-size: vw(28);
        color: $orange;
        margin-bottom: vw(40);
        @media #{$info-phone} {
          font-size: pv(40);
          margin-bottom: 3vw;
        }
      }

      p {
        font-size: vw(18);
        line-height: 1.4;
        @media #{$info-phone} {
          font-size: pv(32);
          text-align: left;
        }
      }
    }

    .s3_img {
      flex-basis: 42%;
      max-width: 42%;
      text-align: right;
      @media #{$info-phone} {
        flex-basis: 100%;
        max-width: 100%;
        text-align: center;
        margin: 2vw auto 0;
      }

      img {
        max-width: vw(383);
        @media #{$info-phone} {
          max-width: 100%;
        }
      }
    }

  }

  .s4 {
    margin: 0 auto vw(70);
    text-align: center;
    @media #{$info-phone} {
      margin: 0 auto 5vw;
    }

    h2 {
      font-size: vw(48);
      letter-spacing: 0.01em;
      -webkit-text-stroke: 1px $orange;
      color: #fff;
      line-height: 1;
      margin-bottom: vw(10);
      position: relative;
      @media #{$info-phone} {
        font-size: pv(72);
        margin-bottom: 2vw;
      }
    }

    h3 {
      font-size: vw(28);
      color: $orange;
      margin-bottom: vw(40);
      @media #{$info-phone} {
        font-size: pv(40);
        margin-bottom: 3vw;
      }
    }

    p {
      font-size: vw(18);
      line-height: 1.7;
      margin-bottom: vw(50);
      @media #{$info-phone} {
        font-size: pv(32);
        margin-bottom: 2vw;
        text-align: left;
      }
    }

    img {
      max-width: 100%;
    }

  }

  .s5 {
    margin: 0 auto vw(70);
    text-align: center;
    @media #{$info-phone} {
      margin: 0 auto 5vw;
    }

    h2 {
      font-size: vw(48);
      letter-spacing: 0.01em;
      -webkit-text-stroke: 1px $orange;
      color: #fff;
      line-height: 1;
      margin-bottom: vw(10);
      position: relative;
      @media #{$info-phone} {
        font-size: pv(72);
        margin-bottom: 2vw;
      }
    }

    h3 {
      font-size: vw(28);
      color: $orange;
      margin-bottom: vw(40);
      @media #{$info-phone} {
        font-size: pv(40);
        margin-bottom: 4vw;
      }
    }

    p {
      font-size: vw(18);
      line-height: 1.7;
      margin-bottom: vw(50);
      @media #{$info-phone} {
        font-size: pv(32);
        margin-bottom: 2vw;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0 auto vw(35);
      @media #{$info-phone} {
        flex-direction: column;
        margin: 0 auto 3.5vw;
      }
    }

    li {
      flex-basis: vw(310);
      max-width: vw(310);
      border-radius: 6px;
      border: 1px solid $orange;
      background-color: #fff;
      padding: vw(15);
      position: relative;
      @media #{$info-phone} {
        flex-basis: 100%;
        max-width: 100%;
        padding: 4vw;
        margin-bottom: 5vw;
      }

      .s5_ttl_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$info-phone} {
          flex-direction: column;
        }
      }

      .s5_ttl_bx {

        h4 {
          font-size: vw(50);
          letter-spacing: -0.02em;
          font-weight: 300;
          color: $orange-gray;
          @media #{$info-phone} {
            font-size: pv(100);
            margin-bottom: 2vw;
          }
        }

        h5 {
          font-size: vw(14);
          color: $orange;
          line-height: 1;
          @media #{$info-phone} {
            font-size: pv(32);
          }
        }
      }

      h6 {
        margin: 15px 0;
        text-align: left;
        font-size: vw(16);
        line-height: 2;
        font-weight: normal;
        @media #{$info-phone} {
          margin: 2.5vw 0;
          font-size: pv(32);
        }
      }

      img {
        max-width: vw(280);
        margin: 10px auto 0;
        @media #{$info-phone} {
          max-width: 100%;
        }
      }
    }

    li:after {
      content: "";
      background-image: url("../assets/images/page-other/step_arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: vw(44);
      height: vw(44);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      right: vw(-24);
      z-index: 3;
      @media #{$info-phone} {
        background-image: url("../assets/images/page-other/step_arrow-s.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 11vw;
        height: 11vw;
        top: inherit;
        bottom: -8vw;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        right: inherit;
      }
    }

    li:last-child{
      margin-bottom: 0;
      &:after{
        content: none;
      }
    }

  }

  .s6 {
    margin: 0 auto vw(70);
    text-align: center;
    @media #{$info-phone} {
      margin: 0 auto 5vw;
    }

    h2 {
      font-size: vw(48);
      letter-spacing: 0.01em;
      -webkit-text-stroke: 1px $orange;
      color: #fff;
      line-height: 1;
      margin-bottom: vw(10);
      position: relative;
      @media #{$info-phone} {
        font-size: pv(72);
        line-height: 1.3;
        margin-bottom: 2vw;
      }
    }

    h3 {
      font-size: vw(28);
      color: $orange;
      margin-bottom: vw(40);
      @media #{$info-phone} {
        font-size: pv(40);
        margin-bottom: 3vw;
      }
    }

    p {
      font-size: vw(18);
      line-height: 1.7;
      text-align: left;
      margin-bottom: 22px;
      @media #{$info-phone} {
        font-size: pv(32);
      }
    }

    img {
      max-width: 100%;
      margin-bottom: 25px;
      @media #{$info-phone} {
        margin-bottom: 5vw;
      }
    }
  }

  .text-indent {
    margin-bottom: 20px;
  }
}

</style>
